
import { defineComponent } from 'vue'
import TmButton from '@/components/shared/TmButton.vue'
import { useModals } from '@/compositions/modals'
import DetailsTable from '@/components/shared/templates/details/DetailsTable.vue'
import DetailsTableItem from '@/components/shared/templates/details/DetailsTableItem.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmPerson from '@/components/shared/TmPerson.vue'

export default defineComponent({
  components: { TmPerson, TmStatus, DetailsTableItem, DetailsTable, TmButton },
  setup() {
    const { openModal } = useModals()
    const openListsShareModal = () => {
      openModal('confirmation', {
        title: 'Share with sub-accounts',
        text: 'After sharing the selected lists, your sub-accounts will be able to view, edit and delete the contacts in these lists. Are you sure you want to continue?',
        btnText: 'Share lists',
      })
    }
    const openDeleteListModal = () => {
      openModal('confirmation', {
        title: 'Delete lists',
        text: 'Any contacts contained only in selected list will be deleted permanently. Are you sure you would like to delete selected list?',
        btnText: 'Delete',
        btnColor: 'red',
      })
    }
    const openDeleteContactModal = () => {
      openModal('confirmation', {
        title: 'Delete contact',
        text: [
          { text: 'Ralph Edwards (603) 555-0123', style: 'semi-bold' },
          ' will be deleted permanently. Are you sure you would like to delete it?',
        ],
        btnText: 'Delete contact',
        btnColor: 'red',
      })
    }
    const openBlockConfirmationModal = () => {
      openModal('confirmation', {
        title: 'Block confirmation',
        text: [
          'You will no longer be able to exchange messages or calls with',
          { text: ' Ralph Edwards (603) 555-0123', style: 'semi-bold' },
          ' once it is blocked.',
        ],
        btnText: 'Block',
        btnColor: 'red',
      })
    }
    const openDeleteCustomFieldModal = () => {
      openModal('confirmation', {
        title: 'Delete custom field',
        text: 'Please note! Any data that was contained in field will be permanently deleted. Are you sure you would like to delete selected field?',
        btnText: 'Delete',
        btnColor: 'red',
      })
    }

    return {
      openListsShareModal,
      openDeleteListModal,
      openDeleteContactModal,
      openBlockConfirmationModal,
      openDeleteCustomFieldModal,
    }
  },
})
